/* src/App.css */
.App {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    background-image: url('./background.jpg'); /* 确保路径正确 */
    background-size: cover;
    background-position: center;
  }
  